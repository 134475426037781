import { FormControlLabel, Checkbox } from "@mui/material";
import React from "react";
import { schedule } from "../../pages/Main";
import { useTranslation } from "react-i18next";

const CheckItem = (props: {
    schedules: schedule[];
    setSchedule: React.Dispatch<React.SetStateAction<schedule[] | undefined>>;
    currentSchedule: schedule;
}) => {
    const [check, setCheck] = React.useState(props.currentSchedule.done);
    const {t} = useTranslation();

    const checkRead = (daySchedule: schedule, checked:boolean) => {
        const newSchedule = props.schedules?.map(s => {
            if (s.data[0].book === daySchedule.data[0].book && s.data[0].chapter === daySchedule.data[0].chapter){
                return {...s, done: checked}
            } else {
                return s
            }
        })
        props.setSchedule(newSchedule);
    }

    const bookAndChapter = props.currentSchedule.data.map((s, i) => {
        const book = t(`books.${s.book}`)
        return `${i>0 ? '/ ': ''}${book} ${s.chapter}`
    })

    return (
        <FormControlLabel 
            control = {
                <Checkbox 
                    checked={check}
                    onChange={ev => {
                        setCheck(ev.target.checked)
                        checkRead(props.currentSchedule, ev.target.checked)
                    }}
                />
            } 
            label={bookAndChapter.join('')}
            sx={{ml: 1}}
        />
    )
}
export default CheckItem;