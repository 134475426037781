import { createTheme } from "@mui/material";

export const primaryColor = '#90837e'
export const secondaryColor = '#5d4037'
// export const primaryColor = '#6b5751'
// export const secondaryColor = '#f59700'

// '#424869', '#d09d4f'

export const defaultTheme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});

