import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../Providers/UserProvider";
import { supabase } from '../supabaseClient'
import {
    Auth,
} from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Box, CircularProgress } from "@mui/material";
// import { primaryColor, secondaryColor } from "../Providers/theme";
import { useTranslation } from "react-i18next";
import { Session } from "@supabase/supabase-js";

const SignIn = () => {
    const navigate = useNavigate();
    const user = useContext(UserInfoContext);
    const {t} = useTranslation();

    const [session, setSession] = useState<Session|null>(null)

    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
      })

      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
      })

      return () => subscription.unsubscribe()
    }, [])

    useEffect(()=>{        
        // if(typeof user?.user !== "undefined"){navigate('/')}
        if(session){navigate('/')}
    },[navigate, user])

    const localization = {
        "sign_up": {
          "email_label": t('auth.sign_up.email_label'),
          "password_label": t('auth.sign_up.password_label'),
          "email_input_placeholder": t('auth.sign_up.email_input_placeholder'),
          "password_input_placeholder": t('auth.sign_up.password_input_placeholder'),
          "button_label": t('auth.sign_up.button_label'),
          "social_provider_text": t('auth.sign_up.social_provider_text'),
          "link_text": t('auth.sign_up.link_text')
        },
        "sign_in": {
          "email_label": t('auth.sign_in.email_label'),
          "password_label": t('auth.sign_in.password_label'),
          "email_input_placeholder": t('auth.sign_in.email_input_placeholder'),
          "password_input_placeholder": t('auth.sign_in.password_input_placeholder'),
          "button_label": t('auth.sign_in.button_label'),
          "social_provider_text": t('auth.sign_in.social_provider_text'),
          "link_text": t('auth.sign_in.link_text')
        },
        "magic_link": {
          "email_input_label": t('auth.magic_link.email_input_label'),
          "email_input_placeholder": t('auth.magic_link.email_input_placeholder'),
          "button_label":t('auth.magic_link.button_label'),
          "link_text": t('auth.magic_link.link_text')
        },
        "forgotten_password": {
          "email_label": t('auth.forgotten_password.email_label'),
          "password_label": t('auth.forgotten_password.password_label'),
          "email_input_placeholder": t('auth.forgotten_password.email_input_placeholder'),
          "button_label": t('auth.forgotten_password.button_label'),
          "link_text": t('auth.forgotten_password.link_text')
        },
        "update_password": {
          "password_label": t('auth.update_password.password_label'),
          "password_input_placeholder": t('auth.update_password.password_input_placeholder'),
          "button_label": t('auth.update_password.button_label')
        }
      }
      
    
  return (
    <Box sx={{py: 2, px: 4, maxWidth: 400, mx: 'auto'}}>
        {user?.loaded 
        ?   <Auth 
                supabaseClient={supabase} 
                appearance={{ 
                    theme: ThemeSupa,
                    // variables: {
                    //     default: {
                    //     colors: {
                    //         brand: primaryColor,
                    //         brandAccent: secondaryColor,
                    //     },
                    //     },
                    // },
                }}
                // localization={{variables: localization}}
                // providers={[]}
            />
        :   <CircularProgress />
        }
    </Box>
  );
};

export default SignIn;

