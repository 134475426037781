import { Session } from "@supabase/gotrue-js";
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import i18n, { Language } from "../lang/configs";
import { supabase } from "../supabaseClient";

export const useUser = () => useContext(UserInfoContext);

type UserInfo = {
    id: string;
}

type UserContext = {
  user?: UserInfo;
  setUser?: React.Dispatch<React.SetStateAction<UserInfo | undefined>>;
  lang: Language|undefined;
  setLang: React.Dispatch<React.SetStateAction<Language|undefined>>;
  loaded: boolean;
}

export const UserInfoContext: React.Context<UserContext|undefined> = createContext<UserContext|undefined>(undefined);

export const UserProvider = (props: {children: ReactNode}) => {
    const [user, setUser] = useState<UserInfo>();
    const [loaded, setLoaded] = useState(false);
    const [lang, setLang] = useState<Language>();

    const manageUser = (session: Session|null) => {
      if(!session){return}
      setUser({id: session?.user.id})
    }
    const updateLang = useCallback(async() =>{
      await supabase.from('userInfo').update({'language': lang}).eq('userId', user?.id)
    },[lang, user?.id])

    // 言語設定
    const langSetting = useCallback(
      async()=>{
        // user情報があれば言語取得
        const userLang = (await supabase.from('userInfo').select('language').eq('userId', user?.id)).data?.[0].language;
        if(userLang){
          if(lang){ // UserInfoにuser情報がある場合
            if(lang !== userLang){ // lang設定があってサーバーの言語と違うならサーバーにlang情報を送る
              updateLang();
            }
          } else { // lang設定なければサーバーの言語をsetLang
            setLang(userLang);
          }
        }else if(lang){
          supabase.from('userInfo').insert([{'userId': user?.id, 'language': lang}])
        }
      }
,[lang, updateLang, user?.id]    ) 


    useEffect(()=>{
      if(!user?.id){return}
      langSetting();
    }, [langSetting, user?.id])

    useEffect(()=>{
      i18n.changeLanguage(lang);
      if(!user?.id){return}
      updateLang();
    }, [lang, updateLang, user?.id])
  
    // セッション情報取得
    useEffect(()=>{
        supabase.auth.getSession().then(({ data: { session } }) => {
          manageUser(session)
        }).finally(()=>{
          setLoaded(true);
        })
    
        supabase.auth.onAuthStateChange((_event, session) => {
          manageUser(session)
        })
        
      }, [])
    
    return(
        <UserInfoContext.Provider value={{user, setUser, lang, setLang, loaded}}>
            {props.children}
        </UserInfoContext.Provider>
    )
}
export default UserProvider;