export const defaultSchedule = [
 [{book: 'Genesis', chapter: '1-3'}],   
 [{book: 'Genesis', chapter: '4-7'}],   
 [{book: 'Genesis', chapter: '8-11'}],   
 [{book: 'Genesis', chapter: '12-15'}],   
 [{book: 'Genesis', chapter: '16-18'}],   
 [{book: 'Genesis', chapter: '19-22'}],   
 [{book: 'Genesis', chapter: '23-24'}],   
 [{book: 'Genesis', chapter: '25-27'}],   
 [{book: 'Genesis', chapter: '28-30'}],   
 [{book: 'Genesis', chapter: '31-32'}],   
 [{book: 'Genesis', chapter: '33-34'}],   
 [{book: 'Genesis', chapter: '35-37'}],   
 [{book: 'Genesis', chapter: '38-40'}],   
 [{book: 'Genesis', chapter: '41-42'}],   
 [{book: 'Genesis', chapter: '43-45'}],   
 [{book: 'Genesis', chapter: '46-48'}],   
 [{book: 'Genesis', chapter: '49-50'}],   
 [{book: 'Exodus', chapter: '1-4'}],   
 [{book: 'Exodus', chapter: '5-7'}],   
 [{book: 'Exodus', chapter: '8-10'}],   
 [{book: 'Exodus', chapter: '11-13'}],   
 [{book: 'Exodus', chapter: '14-15'}],   
 [{book: 'Exodus', chapter: '16-18'}],   
 [{book: 'Exodus', chapter: '19-21'}],   
 [{book: 'Exodus', chapter: '22-25'}],   
 [{book: 'Exodus', chapter: '26-28'}],   
 [{book: 'Exodus', chapter: '29-30'}],   
 [{book: 'Exodus', chapter: '31-33'}],   
 [{book: 'Exodus', chapter: '34-35'}],   
 [{book: 'Exodus', chapter: '36-38'}],   
 [{book: 'Exodus', chapter: '39-40'}],   
 [{book: 'Leviticus', chapter: '1-4'}],   
 [{book: 'Leviticus', chapter: '5-7'}],   
 [{book: 'Leviticus', chapter: '8-10'}],   
 [{book: 'Leviticus', chapter: '11-13'}],   
 [{book: 'Leviticus', chapter: '14-15'}],   
 [{book: 'Leviticus', chapter: '16-18'}],   
 [{book: 'Leviticus', chapter: '19-21'}],   
 [{book: 'Leviticus', chapter: '22-23'}],   
 [{book: 'Leviticus', chapter: '24-25'}],   
 [{book: 'Leviticus', chapter: '26-27'}],   
 [{book: 'Numbers', chapter: '1-3'}],   
 [{book: 'Numbers', chapter: '4-6'}],   
 [{book: 'Numbers', chapter: '7-9'}],   
 [{book: 'Numbers', chapter: '10-12'}],   
 [{book: 'Numbers', chapter: '13-15'}],   
 [{book: 'Numbers', chapter: '16-18'}],   
 [{book: 'Numbers', chapter: '19-21'}],   
 [{book: 'Numbers', chapter: '22-24'}],   
 [{book: 'Numbers', chapter: '25-27'}],   
 [{book: 'Numbers', chapter: '28-30'}],   
 [{book: 'Numbers', chapter: '31-32'}],   
 [{book: 'Numbers', chapter: '33-36'}],   
 [{book: 'Deuteronomy', chapter: '1-2'}],   
 [{book: 'Deuteronomy', chapter: '3-4'}],   
 [{book: 'Deuteronomy', chapter: '5-7'}],   
 [{book: 'Deuteronomy', chapter: '8-10'}],   
 [{book: 'Deuteronomy', chapter: '11-13'}],   
 [{book: 'Deuteronomy', chapter: '14-16'}],   
 [{book: 'Deuteronomy', chapter: '17-19'}],   
 [{book: 'Deuteronomy', chapter: '20-22'}],   
 [{book: 'Deuteronomy', chapter: '23-26'}],   
 [{book: 'Deuteronomy', chapter: '27-28'}],   
 [{book: 'Deuteronomy', chapter: '29-31'}],   
 [{book: 'Deuteronomy', chapter: '32'}],   
 [{book: 'Deuteronomy', chapter: '33-34'}],   
 [{book: 'Joshua', chapter: '1-4'}],   
 [{book: 'Joshua', chapter: '5-7'}],   
 [{book: 'Joshua', chapter: '8-9'}],   
 [{book: 'Joshua', chapter: '10-12'}],   
 [{book: 'Joshua', chapter: '13-15'}],   
 [{book: 'Joshua', chapter: '16-18'}],   
 [{book: 'Joshua', chapter: '19-21'}],   
 [{book: 'Joshua', chapter: '22-24'}],   
 [{book: 'Judges', chapter: '1-2'}],   
 [{book: 'Judges', chapter: '3-5'}],   
 [{book: 'Judges', chapter: '6-7'}],   
 [{book: 'Judges', chapter: '8-9'}],   
 [{book: 'Judges', chapter: '10-11'}],   
 [{book: 'Judges', chapter: '12-13'}],   
 [{book: 'Judges', chapter: '14-16'}],   
 [{book: 'Judges', chapter: '17-19'}],   
 [{book: 'Judges', chapter: '20-21'}],   
 [{book: 'Ruth', chapter: '1-4'}],
 [{book: '1Samuel', chapter: '1-2'}],
 [{book: '1Samuel', chapter: '3-6'}],
 [{book: '1Samuel', chapter: '7-9'}],
 [{book: '1Samuel', chapter: '10-12'}],
 [{book: '1Samuel', chapter: '13-14'}],
 [{book: '1Samuel', chapter: '15-16'}],
 [{book: '1Samuel', chapter: '17-18'}],
 [{book: '1Samuel', chapter: '19-21'}],
 [{book: '1Samuel', chapter: '22-24'}],
 [{book: '1Samuel', chapter: '25-27'}],
 [{book: '1Samuel', chapter: '28-31'}],
 [{book: '2Samuel', chapter: '1-2'}],
 [{book: '2Samuel', chapter: '3-5'}],
 [{book: '2Samuel', chapter: '6-8'}],
 [{book: '2Samuel', chapter: '9-12'}],
 [{book: '2Samuel', chapter: '13-14'}],
 [{book: '2Samuel', chapter: '15-16'}],
 [{book: '2Samuel', chapter: '17-18'}],
 [{book: '2Samuel', chapter: '19-20'}],
 [{book: '2Samuel', chapter: '21-22'}],
 [{book: '2Samuel', chapter: '23-24'}],
 [{book: '1Kings', chapter: '1-2'}],
 [{book: '1Kings', chapter: '3-5'}],
 [{book: '1Kings', chapter: '6-7'}],
 [{book: '1Kings', chapter: '8'}],
 [{book: '1Kings', chapter: '9-10'}],
 [{book: '1Kings', chapter: '11-12'}],
 [{book: '1Kings', chapter: '13-14'}],
 [{book: '1Kings', chapter: '15-17'}],
 [{book: '1Kings', chapter: '18-19'}],
 [{book: '1Kings', chapter: '20-21'}],
 [{book: '1Kings', chapter: '22'}],
 [{book: '2Kings', chapter: '1-3'}],
 [{book: '2Kings', chapter: '4-5'}],
 [{book: '2Kings', chapter: '6-8'}],
 [{book: '2Kings', chapter: '9-10'}],
 [{book: '2Kings', chapter: '11-13'}],
 [{book: '2Kings', chapter: '14-15'}],
 [{book: '2Kings', chapter: '16-17'}],
 [{book: '2Kings', chapter: '18-19'}],
 [{book: '2Kings', chapter: '20-22'}],
 [{book: '2Kings', chapter: '23-25'}],
 [{book: '1Chronicles', chapter: '1-2'}],
 [{book: '1Chronicles', chapter: '3-5'}],
 [{book: '1Chronicles', chapter: '6-7'}],
 [{book: '1Chronicles', chapter: '8-10'}],
 [{book: '1Chronicles', chapter: '11-12'}],
 [{book: '1Chronicles', chapter: '13-15'}],
 [{book: '1Chronicles', chapter: '16-17'}],
 [{book: '1Chronicles', chapter: '18-20'}],
 [{book: '1Chronicles', chapter: '21-23'}],
 [{book: '1Chronicles', chapter: '24-26'}],
 [{book: '1Chronicles', chapter: '27-29'}],
 [{book: '2Chronicles', chapter: '1-3'}],
 [{book: '2Chronicles', chapter: '4-6'}],
 [{book: '2Chronicles', chapter: '7-9'}],
 [{book: '2Chronicles', chapter: '10-14'}],
 [{book: '2Chronicles', chapter: '15-18'}],
 [{book: '2Chronicles', chapter: '19-22'}],
 [{book: '2Chronicles', chapter: '23-25'}],
 [{book: '2Chronicles', chapter: '26-28'}],
 [{book: '2Chronicles', chapter: '29-30'}],
 [{book: '2Chronicles', chapter: '31-33'}],
 [{book: '2Chronicles', chapter: '34-36'}],
 [{book: 'Ezra', chapter: '1-3'}],
 [{book: 'Ezra', chapter: '4-7'}],
 [{book: 'Ezra', chapter: '8-10'}],
 [{book: 'Nehemiah', chapter: '1-3'}],
 [{book: 'Nehemiah', chapter: '4-6'}],
 [{book: 'Nehemiah', chapter: '7-8'}],
 [{book: 'Nehemiah', chapter: '9-10'}],
 [{book: 'Nehemiah', chapter: '11-13'}],
 [{book: 'Esther', chapter: '1-4'}],
 [{book: 'Esther', chapter: '5-10'}],
 [{book: 'Job', chapter: '1-5'}],
 [{book: 'Job', chapter: '6-9'}],
 [{book: 'Job', chapter: '10-14'}],
 [{book: 'Job', chapter: '15-18'}],
 [{book: 'Job', chapter: '19-20'}],
 [{book: 'Job', chapter: '21-24'}],
 [{book: 'Job', chapter: '25-29'}],
 [{book: 'Job', chapter: '30-31'}],
 [{book: 'Job', chapter: '32-34'}],
 [{book: 'Job', chapter: '35-38'}],
 [{book: 'Job', chapter: '39-42'}],
 [{book: 'Psalms', chapter: '1-8'}],
 [{book: 'Psalms', chapter: '9-16'}],
 [{book: 'Psalms', chapter: '17-19'}],
 [{book: 'Psalms', chapter: '20-25'}],
 [{book: 'Psalms', chapter: '26-31'}],
 [{book: 'Psalms', chapter: '32-35'}],
 [{book: 'Psalms', chapter: '36-38'}],
 [{book: 'Psalms', chapter: '39-42'}],
 [{book: 'Psalms', chapter: '43-47'}],
 [{book: 'Psalms', chapter: '48-52'}],
 [{book: 'Psalms', chapter: '53-58'}],
 [{book: 'Psalms', chapter: '59-64'}],
 [{book: 'Psalms', chapter: '65-68'}],
 [{book: 'Psalms', chapter: '69-72'}],
 [{book: 'Psalms', chapter: '73-77'}],
 [{book: 'Psalms', chapter: '78-79'}],
 [{book: 'Psalms', chapter: '80-86'}],
 [{book: 'Psalms', chapter: '87-90'}],
 [{book: 'Psalms', chapter: '91-96'}],
 [{book: 'Psalms', chapter: '97-103'}],
 [{book: 'Psalms', chapter: '104-105'}],
 [{book: 'Psalms', chapter: '106-108'}],
 [{book: 'Psalms', chapter: '109-115'}],
 [{book: 'Psalms', chapter: '116-119:63'}],
 [{book: 'Psalms', chapter: '119:64-176'}],
 [{book: 'Psalms', chapter: '120-129'}],
 [{book: 'Psalms', chapter: '130-138'}],
 [{book: 'Psalms', chapter: '139-144'}],
 [{book: 'Psalms', chapter: '145-150'}],
 [{book: 'Proverbs', chapter: '1-4'}],
 [{book: 'Proverbs', chapter: '5-8'}],
 [{book: 'Proverbs', chapter: '9-12'}],
 [{book: 'Proverbs', chapter: '13-16'}],
 [{book: 'Proverbs', chapter: '17-19'}],
 [{book: 'Proverbs', chapter: '20-22'}],
 [{book: 'Proverbs', chapter: '23-27'}],
 [{book: 'Proverbs', chapter: '28-31'}],
 [{book: 'Ecclesiastes', chapter: '1-4'}],
 [{book: 'Ecclesiastes', chapter: '5-8'}],
 [{book: 'Ecclesiastes', chapter: '9-12'}],
 [{book: 'SongofSolomon', chapter: '1-8'}],
 [{book: 'Isaiah', chapter: '1-4'}],
 [{book: 'Isaiah', chapter: '5-7'}],
 [{book: 'Isaiah', chapter: '8-10'}],
 [{book: 'Isaiah', chapter: '11-14'}],
 [{book: 'Isaiah', chapter: '15-19'}],
 [{book: 'Isaiah', chapter: '20-24'}],
 [{book: 'Isaiah', chapter: '25-28'}],
 [{book: 'Isaiah', chapter: '29-31'}],
 [{book: 'Isaiah', chapter: '32-35'}],
 [{book: 'Isaiah', chapter: '36-37'}],
 [{book: 'Isaiah', chapter: '38-40'}],
 [{book: 'Isaiah', chapter: '41-43'}],
 [{book: 'Isaiah', chapter: '44-47'}],
 [{book: 'Isaiah', chapter: '48-50'}],
 [{book: 'Isaiah', chapter: '51-55'}],
 [{book: 'Isaiah', chapter: '56-58'}],
 [{book: 'Isaiah', chapter: '59-62'}],
 [{book: 'Isaiah', chapter: '63-66'}],
 [{book: 'Jeremiah', chapter: '1-3'}],
 [{book: 'Jeremiah', chapter: '4-5'}],
 [{book: 'Jeremiah', chapter: '6-7'}],
 [{book: 'Jeremiah', chapter: '8-10'}],
 [{book: 'Jeremiah', chapter: '11-13'}],
 [{book: 'Jeremiah', chapter: '14-16'}],
 [{book: 'Jeremiah', chapter: '17-20'}],
 [{book: 'Jeremiah', chapter: '21-23'}],
 [{book: 'Jeremiah', chapter: '24-26'}],
 [{book: 'Jeremiah', chapter: '27-29'}],
 [{book: 'Jeremiah', chapter: '30-31'}],
 [{book: 'Jeremiah', chapter: '32-33'}],
 [{book: 'Jeremiah', chapter: '34-36'}],
 [{book: 'Jeremiah', chapter: '37-39'}],
 [{book: 'Jeremiah', chapter: '40-42'}],
 [{book: 'Jeremiah', chapter: '43-44'}],
 [{book: 'Jeremiah', chapter: '45-48'}],
 [{book: 'Jeremiah', chapter: '49-50'}],
 [{book: 'Jeremiah', chapter: '51-52'}],
 [{book: 'Lamentatins', chapter: '1-2'}],
 [{book: 'Lamentatins', chapter: '3-5'}],
 [{book: 'Ezekiel', chapter: '1-3'}],
 [{book: 'Ezekiel', chapter: '4-6'}],
 [{book: 'Ezekiel', chapter: '7-9'}],
 [{book: 'Ezekiel', chapter: '10-12'}],
 [{book: 'Ezekiel', chapter: '13-15'}],
 [{book: 'Ezekiel', chapter: '16'}],
 [{book: 'Ezekiel', chapter: '17-18'}],
 [{book: 'Ezekiel', chapter: '19-21'}],
 [{book: 'Ezekiel', chapter: '22-23'}],
 [{book: 'Ezekiel', chapter: '24-26'}],
 [{book: 'Ezekiel', chapter: '27-28'}],
 [{book: 'Ezekiel', chapter: '29-31'}],
 [{book: 'Ezekiel', chapter: '32-33'}],
 [{book: 'Ezekiel', chapter: '34-36'}],
 [{book: 'Ezekiel', chapter: '37-38'}],
 [{book: 'Ezekiel', chapter: '39-40'}],
 [{book: 'Ezekiel', chapter: '41-43'}],
 [{book: 'Ezekiel', chapter: '44-45'}],
 [{book: 'Ezekiel', chapter: '46-48'}],
 [{book: 'Daniel', chapter: '1-2'}],
 [{book: 'Daniel', chapter: '3-4'}],
 [{book: 'Daniel', chapter: '5-7'}],
 [{book: 'Daniel', chapter: '8-10'}],
 [{book: 'Daniel', chapter: '11-12'}],
 [{book: 'Hosea', chapter: '1-7'}],
 [{book: 'Hosea', chapter: '8-14'}],
 [{book: 'Joel', chapter: '1-3'}],
 [{book: 'Amos', chapter: '1-5'}],
 [{book: 'Amos', chapter: '6-9'}],
 [{book: 'Obadiah', chapter: ''}, {book: 'Jonah', chapter: ''}],
 [{book: 'Micah', chapter: '1-7'}],
 [{book: 'Nahum', chapter: ''}, {book: 'Habakkuk', chapter: ''}],
 [{book: 'Zephaniah', chapter: ''}, {book: 'Haggai', chapter: ''}],
 [{book: 'Zechariah', chapter: '1-7'}],
 [{book: 'Zechariah', chapter: '8-11'}],
 [{book: 'Zechariah', chapter: '12-14'}],
 [{book: 'Malachi', chapter: '1-4'}],
 [{book: 'Matthew', chapter: '1-4'}],
 [{book: 'Matthew', chapter: '5-7'}],
 [{book: 'Matthew', chapter: '8-10'}],
 [{book: 'Matthew', chapter: '11-13'}],
 [{book: 'Matthew', chapter: '14-17'}],
 [{book: 'Matthew', chapter: '18-20'}],
 [{book: 'Matthew', chapter: '21-23'}],
 [{book: 'Matthew', chapter: '24-25'}],
 [{book: 'Matthew', chapter: '26'}],
 [{book: 'Matthew', chapter: '27-28'}],
 [{book: 'Mark', chapter: '1-3'}],
 [{book: 'Mark', chapter: '4-5'}],
 [{book: 'Mark', chapter: '6-8'}],
 [{book: 'Mark', chapter: '9-10'}],
 [{book: 'Mark', chapter: '11-13'}],
 [{book: 'Mark', chapter: '14-16'}],
 [{book: 'Luke', chapter: '1-2'}],
 [{book: 'Luke', chapter: '3-5'}],
 [{book: 'Luke', chapter: '6-7'}],
 [{book: 'Luke', chapter: '8-9'}],
 [{book: 'Luke', chapter: '10-11'}],
 [{book: 'Luke', chapter: '12-13'}],
 [{book: 'Luke', chapter: '14-17'}],
 [{book: 'Luke', chapter: '18-19'}],
 [{book: 'Luke', chapter: '20-22'}],
 [{book: 'Luke', chapter: '23-24'}],
 [{book: 'John', chapter: '1-3'}],
 [{book: 'John', chapter: '4-5'}],
 [{book: 'John', chapter: '6-7'}],
 [{book: 'John', chapter: '8-9'}],
 [{book: 'John', chapter: '10-12'}],
 [{book: 'John', chapter: '13-15'}],
 [{book: 'John', chapter: '16-18'}],
 [{book: 'John', chapter: '19-21'}],
 [{book: 'Acts', chapter: '1-3'}],
 [{book: 'Acts', chapter: '4-6'}],
 [{book: 'Acts', chapter: '7-8'}],
 [{book: 'Acts', chapter: '9-11'}],
 [{book: 'Acts', chapter: '12-14'}],
 [{book: 'Acts', chapter: '15-16'}],
 [{book: 'Acts', chapter: '17-19'}],
 [{book: 'Acts', chapter: '20-21'}],
 [{book: 'Acts', chapter: '22-23'}],
 [{book: 'Acts', chapter: '24-26'}],
 [{book: 'Acts', chapter: '27-28'}],
 [{book: 'Romans', chapter: '1-3'}],
 [{book: 'Romans', chapter: '4-7'}],
 [{book: 'Romans', chapter: '8-11'}],
 [{book: 'Romans', chapter: '12-16'}],
 [{book: '1Corinthians', chapter: '1-6'}],
 [{book: '1Corinthians', chapter: '7-10'}],
 [{book: '1Corinthians', chapter: '11-14'}],
 [{book: '1Corinthians', chapter: '15-16'}],
 [{book: '2Corinthians', chapter: '1-6'}],
 [{book: '2Corinthians', chapter: '7-10'}],
 [{book: '2Corinthians', chapter: '11-13'}],
 [{book: 'Galatians', chapter: '1-6'}],
 [{book: 'Ephesians', chapter: '1-6'}],
 [{book: 'Philippians', chapter: '1-4'}],
 [{book: 'Colossians', chapter: '1-4'}],
 [{book: '1Thessalonians', chapter: '1-5'}],
 [{book: '2Thessalonians', chapter: '1-3'}],
 [{book: '1Timothy', chapter: '1-6'}],
 [{book: '2Timothy', chapter: '1-4'}],
 [{book: 'Titus', chapter: ''}, {book: 'Philemon', chapter: ''}],
 [{book: 'Hebrews', chapter: '1-6'}],
 [{book: 'Hebrews', chapter: '7-10'}],
 [{book: 'Hebrews', chapter: '11-13'}],
 [{book: 'James', chapter: '1-5'}],
 [{book: '1Peter', chapter: '1-5'}],
 [{book: '2Peter', chapter: '1-3'}],
 [{book: '1John', chapter: '1-5'}],
 [{book: '2John', chapter: ''}, {book: '3John', chapter: ''}, {book: 'Jude', chapter: ''}],
 [{book: 'Revelation', chapter: '1-4'}],
 [{book: 'Revelation', chapter: '5-9'}],
 [{book: 'Revelation', chapter: '10-14'}],
 [{book: 'Revelation', chapter: '15-18'}],
 [{book: 'Revelation', chapter: '19-22'}],
]