import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, Typography } from "@mui/material"
import {  LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useContext, useEffect } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { supabase } from '../supabaseClient'
import { UserInfoContext } from "../Providers/UserProvider";
import { defaultSchedule } from "../datas/defaultSchedule";
import { useTranslation } from "react-i18next";
import { schedule } from "./Main";
import { useNavigate } from "react-router-dom";

const Setting = () => {
    const [value, setValue] = React.useState<Date | null>(null)
    const [dialog, setDialog] = React.useState(false)
    const user = useContext(UserInfoContext);
    const navigate = useNavigate()
    const {t} = useTranslation();

    // スケジュールの有無をチェック
    const checkOwnSchedule = async() => {
        const ownSchedule = (
            await supabase.from('active_schedules')
            .select('schedule')
            .eq('userId', user?.user?.id)
        ).data;
        return (ownSchedule && ownSchedule.length > 0)
    }

    // 開始日チェック
    const checkStartDate = useCallback( async() => {
        const {data} = await supabase
        .from('active_schedules')
        .select('startDate')
        .eq('userId', user?.user?.id);
        setValue(data?.[0].startDate)
    },[user?.user?.id])
    
    useEffect(()=>{
        checkStartDate()
    },[checkStartDate])

    const updateStartDate = async(date: Date) => {
        await supabase.from('active_schedules')
        .update({ 'startDate': date})
        .eq('userId', user?.user?.id)
    }

    // スケジュール生成
    const newSchedule = defaultSchedule.map(data => {
        return {data, done: false, memo: ''};
    })
    // スケジュール追加
    const insertNewSchedule = async(date: Date) => {
        await supabase.from('active_schedules')
        .insert([
            {'userId': user?.user?.id, 'startDate': date, 'schedule': newSchedule }
        ])
    }

    const handleChange = async(date: Date | null) => {
        if(date === null){return};
        // UTCに直す
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()) )
        
        setValue(date);
        await (checkOwnSchedule())? 
            updateStartDate(utcDate ?? new Date()) : 
            insertNewSchedule(utcDate ?? new Date());
    }

    // 全チェックをクリア
    const clearAll = async() => {
        if (!user?.user?.id){return}
        // 自分のスケジュール取得
        const ownSchedule = (
            await supabase.from('active_schedules')
            .select('schedule')
            .eq('userId', user?.user?.id)
        ).data;
        // チェックを全クリア
        const newSchecule = ownSchedule?.[0].schedule.map((s: schedule)=> ({...s, done: false}))
        // supabaseの値を更新
        await supabase.from('active_schedules')
            .update({'schedule': newSchecule})
            .eq('userId', user?.user?.id)
        // ページ遷移
        setDialog(false)
        navigate('/')
    }

    return(
        <>
            <Box sx={{py: 2, px: 4, maxWidth: 400, mx: 'auto'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box sx={{textAlign: 'center'}}>
                        <Typography>{t('setting.setStartDate')}</Typography>
                        <DatePicker
                            onChange={handleChange}
                            value={value && new Date(value)}
                        />
                    </Box>
                </LocalizationProvider>
                <Divider sx={{my: 4}}/>
                <Box sx={{textAlign: 'center'}}>
                    <Typography>{t('setting.clearExplain')}</Typography>
                    <Button variant="contained" sx={{textAlign: 'center', my:1}} onClick={()=>setDialog(true)}>{t('setting.clearAll')}</Button>
                </Box>
            </Box>
            <Dialog open={dialog} onClose={()=>setDialog(false)}>
                <DialogTitle>本当にクリアしますか</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={clearAll}>クリアする</Button>
                    <Button onClick={()=>setDialog(false)}>キャンセル</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Setting;