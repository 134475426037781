import { AppBar, IconButton, Stack, Toolbar } from "@mui/material";
import { headerHeight } from "../../Numbers";
import LogoWhite from '../../images/logo-white.png'
import SettingsIcon from '@mui/icons-material/Settings';
import { useContext, useEffect } from "react";
import { UserInfoContext } from "../../Providers/UserProvider";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import TranslateIcon from '@mui/icons-material/Translate';
import SettingMenu from "../molecules/SettingMenu";
import LangMenu from "../molecules/LangMenu";

const Header = () => {
    const [settingOpen, setSettingOpen] = React.useState<null | HTMLElement>(null);
    const [langOpen, setLangOpen] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSettingOpen(event.currentTarget);
    };
    const openLangMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLangOpen(event.currentTarget);
    };
    const navigate = useNavigate();
    
    const user = useContext(UserInfoContext);
    useEffect(()=>{        
        if(typeof user?.user === "undefined"){
            navigate('/login')
        }
    },[navigate, user])

    return(
        <>
        <AppBar>
            <Toolbar variant="dense">
                <Stack sx={{height: headerHeight, width: '100%'}} direction='row'>
                    <Link to={user?.user ? '/' : '/login'}>
                        <img src={LogoWhite} style={{width: `calc(${headerHeight} - 8px)`, padding: '4px'}} alt=""/>
                    </Link>
                    <IconButton sx={{ml: 'auto'}} onClick={openLangMenu}>
                        <TranslateIcon/>
                    </IconButton>
                    {user?.user ? (
                        <IconButton 
                            onClick={handleClick}
                        >
                            <SettingsIcon/>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </Stack>
                <LangMenu langOpen={langOpen} setLangOpen={setLangOpen}/>
                <SettingMenu anchorEl={settingOpen} setAnchorEl={setSettingOpen}/>
            </Toolbar>
        </AppBar>
        </>
    )
}
export default Header;