import { Menu, MenuItem } from "@mui/material";
import { useContext } from "react";
import { Language } from "../../lang/configs";
import { UserInfoContext } from "../../Providers/UserProvider";

const LangMenu = (props: {
    langOpen: HTMLElement | null;
    setLangOpen: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}) => {
    const user = useContext(UserInfoContext);
    const handleClose = () => {
        props.setLangOpen(null);
      };
    const openSetting = Boolean(props.langOpen);

    const Language = (lang: Language) => {
        user?.setLang(lang)
        handleClose();
    }

    return(
    <Menu 
        anchorEl={props.langOpen}
        open={openSetting}
        onClose={handleClose}
    >
        <MenuItem 
            onClick={()=>{
                Language('en')
            }}
        >
            English
        </MenuItem>
        <MenuItem 
            onClick={()=>{
                Language('ja')
            }}
        >
            日本語
        </MenuItem>
        <MenuItem 
            onClick={()=>{
                Language('bg')
            }}
        >
            Български
        </MenuItem>
    </Menu>
    )

}

export default LangMenu;