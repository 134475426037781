import './App.css';
import Main from './pages/Main';
import Header from './components/organisms/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Setting from './pages/Setting';
import UserProvider from './Providers/UserProvider';
import { ThemeProvider } from '@mui/material/styles';
import {defaultTheme}  from './Providers/theme'
import { Box } from '@mui/material';
import { headerHeight } from './Numbers';
import { Provider as AtomProvider } from "jotai";

function App() {  
  return (
    <BrowserRouter>
      <UserProvider>
        <AtomProvider>
          <ThemeProvider theme={defaultTheme}>          
            <Header/>
            <Box sx={{
                      height: `calc(100% - ${headerHeight})`, 
                      overflow: 'auto',
                      mt: `${headerHeight}`,
                      position: 'absolute',
                      width: '100%'
            }}>            
              <Routes>
                <Route path='/' element={<Main/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/setting' element={<Setting/>}/>
              </Routes>
            </Box>
          </ThemeProvider>
        </AtomProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
