import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../Providers/UserProvider";
import { supabase } from "../supabaseClient";
import CheckItem from "../components/molecules/CheckItem";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { primaryColor } from "../Providers/theme";

export type schedule = {
    done: boolean;
    memo: string;
    data: {
        book: string;
        chapter: string;
    }[];
}

const Main = () => {
    const [startDate, setStartDate] = useState<string>();
    const [schedule, setSchedule] = useState<schedule[]>();
    const user = useContext(UserInfoContext);
    const matches = useMediaQuery('(max-width:420px)');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const getStartDate = useCallback( async() => {
        if(!user?.user){return}
        const {data} = 
        await supabase.from('active_schedules')
        .select('startDate')
        .eq('userId', user?.user?.id)

        data && setStartDate(data?.[0].startDate);
    }, [user?.user])

    const checkOwnSchedule = useCallback( async() => {
        if (!user?.user?.id){return}
        // 自分のスケジュール取得
        const ownSchedule = (
            await supabase.from('active_schedules')
            .select('schedule')
            .eq('userId', user?.user?.id)
        ).data;

        // スケジュールの有無を確認して、あれば出力。なければ設定に行く
        (ownSchedule?.[0].schedule) ? setSchedule(ownSchedule[0].schedule) : navigate('/setting');
    },[navigate, user?.user?.id])

    useEffect(()=>{
        user && getStartDate();
        user && checkOwnSchedule()
    },[checkOwnSchedule, getStartDate, user])

    useEffect(()=>{
        user && (async() => {
            await supabase.from('active_schedules')
            .update({'schedule': schedule})
            .eq('userId', user?.user?.id)
        })()
    },[schedule, user])

    const formatDate = (dateData: Date) => {
        const date = ('0'+dateData.getDate()).slice(-2);
        const month = ('0'+(dateData.getMonth()+1)).slice(-2);
        const thisDateText = `${dateData.getFullYear()}.${month}.${date}`;
        const globalDateText = `${date}.${month}.${dateData.getFullYear()}`
        return {thisDateText, globalDateText}
    }

    const continueDate = () => {
        const index = schedule?.findIndex(e => e.done === false);
        if(!startDate || !index){return}
        const date = new Date(startDate);
        return formatDate(new Date(date.setDate(date.getDate() + index))).thisDateText
    } 

    return (
        <>
            <Stack 
                sx={{
                    flexWrap: (matches ? 'nowrap' :'wrap'), 
                    height: '100%'
                }}
            >
                <Stack 
                    direction={'row'} 
                    justifyContent={matches ? 'center' :'flex-start'} 
                    spacing={2} 
                    sx={{ml: matches ? 0 : 4, pt: 2}}
                    id='top'
                >
                    <Button 
                        name='toToday' 
                        href={`#${formatDate(new Date()).thisDateText}`}
                        variant="contained"
                    >
                        {t('main.today')}
                    </Button>
                    <Button 
                        name='continue' 
                        href={`#${continueDate()}`} 
                        variant="contained"
                    >
                        {t('main.continue')}
                    </Button>
                </Stack>
                {schedule?.map((currentSchedule, index) => {
                    // if(!startDate){return}
                    const theDate = (new Date(startDate ?? new Date()));
                    const thisDate = new Date(theDate.setDate(theDate.getDate() + index));
                    const {thisDateText, globalDateText} = formatDate(thisDate)
                    return(
                            <Box 
                                key={currentSchedule.data[0].book + currentSchedule.data[0].chapter} 
                                sx={{
                                    my: 1, 
                                    mx: 4, 
                                    display: 'grid',
                                    gridTemplateColumns: '75px 1fr',
                                    alignItems: 'center'
                                }}
                                id={thisDateText}
                            >
                                <Typography>
                                    {i18n.language === 'ja' ? thisDateText : globalDateText}
                                </Typography>
                                <CheckItem 
                                    schedules={schedule} 
                                    setSchedule={setSchedule} 
                                    currentSchedule={currentSchedule}
                                />
                            </Box>
                    )
                }) }
            </Stack>
            {matches ?
                <IconButton 
                    sx={{position: 'fixed', right: 8, bottom: 8, bgcolor: primaryColor, height: 50, width: 50}} 
                    href='#top'
                >
                    <Stack spacing={0}>
                        <KeyboardArrowUpIcon sx={{color: '#fff'}}/>
                        <Typography variant="subtitle1" sx={{color: '#fff', fontSize: 12, lineHeight: 0.5}}>top</Typography>
                    </Stack>
                </IconButton>
             : <></>}
        </>
    )
}
export default Main;