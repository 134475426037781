import { Menu, MenuItem } from "@mui/material";
import { useContext } from "react";
import { supabase } from "../../supabaseClient";
import { UserInfoContext } from "../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SettingMenu = (props: {
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}) => {
    const user = useContext(UserInfoContext);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const handleClose = () => {
        props.setAnchorEl(null);
      };
    const openSetting = Boolean(props.anchorEl);

    return(
    <Menu 
        anchorEl={props.anchorEl}
        open={openSetting}
        onClose={handleClose}
    >
        <MenuItem 
            onClick={()=>{
                navigate('/setting');
                handleClose();
            }}
        >
            {t('setting.setting')}
        </MenuItem>
        <MenuItem 
            onClick={async () => {
                try{
                    await supabase.auth.signOut();
                    user?.setUser && user?.setUser(undefined);
                } catch(e) {
                    console.error(e)
                } finally {
                    handleClose();
                    navigate('/login')
                }
            }}
        >
            {t('setting.signout')}
        </MenuItem>
    </Menu>
    )

}

export default SettingMenu;